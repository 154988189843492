import React from 'react'
import {Link, graphql, StaticQuery} from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'

class BlogRoll extends React.Component {
  render() {
    const {data} = this.props
    const {edges: posts} = data.allMarkdownRemark

    return (
      <div className='columns is-multiline'>
        {posts &&
          posts.map(({node: post}, idx) => (
            <div className='column is-one-quarter' key={idx}>
              <Link
                className='has-text-primary has-text-weight-bold'
                to={post.fields.slug}
              >
                <div className='card blog-card' key={post.id}>
                  <div className='card-image'>
                    <figure className='image'>
                      {post.frontmatter.featuredimage ? (
                        <PreviewCompatibleImage
                          imageInfo={{
                            image: post.frontmatter.featuredimage,
                            alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                          }}
                        />
                      ) : null}
                    </figure>
                  </div>
                  <div className='card-content'>
                    <div className='content'>
                      {post.frontmatter.title}
                      <p>{post.frontmatter.date}</p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
      </div>
    )
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: {order: DESC, fields: [frontmatter___date]}
          filter: {frontmatter: {templateKey: {eq: "blog-post"}}}
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 400, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
)
